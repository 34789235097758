.wrapper {
    margin-bottom: 32px;
}

.statement_filters_wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 28px;
}

.filter_block {
    margin-right: 20px;
}

.filter_title {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #122932;
    margin-bottom: 6px;
}

.button_block {
    width: 132px;
    height: 58px;
}

.table_header {
    display: flex;
    justify-content: space-between;
    background: #122932;
    border-radius: 30px 30px 0 0;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    align-items: center;
    color: #FAFAFA;
    min-height: 64px;
    padding: 0 32px;
}

.header_item_long {
    width: 11%;
    word-break: break-all;
}

.header_item_long_link {
    width: 11%;
    word-break: break-all;
    text-decoration: underline;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #122932;
}

.header_item_long_link:hover {
    color: #122932;
    text-decoration: underline;
}

.header_item_short {
    width: 10%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.header_item_short_status {
    width: 10%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_item_short_date{
    width: 10%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.table_row {
    display: flex;
    justify-content: space-between;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    align-items: center;
    color: #122932;
    min-height: 44px;
    max-height: fit-content;
    padding: 0 32px;
    height: 72px;
    background: #FAFAFA;
}

.table_row:nth-child(odd) {
    background: #D6DADB;
}

.without_transactions {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Georgia', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #131313;
    margin-top: 60px;
}

.out_style {
    color: #EE5252;
}

.in_style {
    color: #29A662;
}

.table_footer {
    background: #D6DADB;
    border-radius: 0 0 30px 30px;
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px;
    position: relative;
}

.accounts_counter {
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #122932;
}

.paginator_mobile {
    display: none;
}

@media only screen
and (max-device-width: 768px) {

    .statement_filters_wrapper {
        flex-direction: column;
        align-items: center;
        margin-bottom: 55px;
        padding: 0 16px;
    }

    .client_details_wrapper {
        background: #FAFAFA;
        border-radius: 30px;
        padding: 28px 16px;
        margin-bottom: 28px;
    }

    .title {
        font-size: 20px;
        margin-bottom: 24px;
    }

    .client_info {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    .block {
        width: 100%;
    }

    .row {
        margin-bottom: 12px;
        align-items: flex-start;
    }

    .field_value {
        word-break: break-all;
        color: #0D4C66;
    }

    .table_header {
        position: absolute;
        display: flex;
        justify-content: initial;
        flex-direction: column;
        background: #122932;
        font-family: 'Mulish', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        align-items: center;
        color: #FAFAFA;
        height: fit-content;
        width: 180px;
        padding: 0 0 0 16px;
        border-radius: 30px 0 0 30px;
    }

    .client_details_btn_block {
        width: 100%;
    }

    .add_account_button_wrapper {
        width: 100%;
    }

    .accounts {
        margin-bottom: 87px;
    }

    .header_item_long {
        width: 100%;
        margin-right: 0;
        padding: 12px 10px 12px 8px;
        border-bottom: 1px solid #FAFAFA;
        height: 42px;
        box-sizing: border-box;
    }

    .header_item_short {
        width: 100%;
        text-align: left;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        padding: 12px 0 12px 8px;
        border-bottom: 1px solid #FAFAFA;
        height: 42px;
        box-sizing: border-box;
    }

    .header_item_short:last-child {
        border-bottom: none;
    }

    .header_item_currency {
        width: 100%;
        text-align: left;
        display: flex;
        align-items: center;
        flex-direction: initial;
        justify-content: flex-start;
        padding: 5px 0 5px 8px;
        border-bottom: 1px solid #FAFAFA;
        height: 42px;
        box-sizing: border-box;
    }

    .currency {
        margin-bottom: 0;
        padding-right: 4px;
    }

    .header_item_status {
        width: 100%;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 12px 0 12px 8px;
        height: 42px;
        box-sizing: border-box;
    }

    .table_header:last-child {
        border-bottom: none;
    }

    .table_footer {
        display: none;
    }

    .row_wrapper {
        margin-left: 180px;
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        height: 378px;
    }

    .accounts_table {
        position: relative;
        display: flex;
        height: fit-content;
    }

    .table_row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
        line-height: 130%;
        height: 378px;
        padding: 0;
        background: #FAFAFA;
        min-width: fit-content;
    }

    .header_item_short_status {
        width: 100%;
        text-align: left;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        padding: 12px 0 12px 8px;
        border-bottom: 1px solid #FAFAFA;
        height: 42px;
        box-sizing: border-box;
    }

    .header_item_short_date{
        width: 100%;
        text-align: left;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        padding: 12px 0 12px 8px;
        border-bottom: 1px solid #FAFAFA;
        height: 42px;
        box-sizing: border-box;
    }

    .filter_block {
        margin-right: 0;
        margin-bottom: 16px;
        width: fit-content;
    }

    .button_block {
        width: 100%;
    }

    .header_item_long_link {
        word-break: break-all;
        text-decoration: underline;
        width: 100%;
        text-align: left;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        padding: 12px 0 12px 8px;
        border-bottom: 1px solid #FAFAFA;
        height: 42px;
        box-sizing: border-box;
    }

    .wrapper {
        margin-bottom: 100px;
    }

    .paginator_mobile {
        margin: 16px 0 28px 0;
        background: #FAFAFA;
        border-radius: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px;
    }
}
