.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #122932;
}

.content {
    width: 1440px;
    padding: 0 100px;
    box-sizing: border-box;
    margin: 0 auto;
}

.motion_div {
    width: 100%;
}

.title {
    font-family: 'Kanit', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 96px;
    text-transform: uppercase;
    color: #FAFAFA;
    margin-top: 100px;
    margin-bottom: 60px;
}

.who_we_desc_block {
    margin-left: 105px;
    width: 820px;
    box-sizing: border-box;
    margin-bottom: 140px;
}

.who_we_description {
    padding-left: 36px;
    border-left: 2px solid #009DE0;
    width: 820px;
    box-sizing: border-box;
    margin-bottom: 36px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #FAFAFA;
}

.who_we_description div {
    margin-bottom: 16px;
}

.who_we_description div:last-child {
    margin-bottom: 0;
}

.services_list_block {
    margin-top: 140px;
    margin-bottom: 140px;
}

.services_list_block_title {
    font-family: 'Kanit', serif;
    font-style: italic;
    font-weight: 275;
    font-size: 60px;
    line-height: 130%;
    color: #FAFAFA;
    margin-bottom: 48px;
    width: 75%;
}


.coins_block {
    display: flex;
    justify-content: space-between;
    margin-top: 140px;
    margin-bottom: 140px;
    background: linear-gradient(270deg, #009DE0 0%, #122932 78.65%);
    border-radius: 30px;
    position: relative;
}

.coins_img_block img {
    position: absolute;
    left: 0;
    top: -23px;
}

.coins_description_block {
    height: 278px;
    border-radius: 0 30px 30px 0;
    padding: 64px 64px 64px 50px;
    box-sizing: border-box;
    width: 630px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #FAFAFA;
}

.coins_description_block div {
    margin-bottom: 28px;

    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #FAFAFA;
}

.flex {
    display: flex;
    justify-content: space-between;
}


.flex {
    display: flex;
    justify-content: space-between;
}

.left {
    width: 47%;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: justify;
    color: #FAFAFA;
}

.right {
    width: 47%;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: justify;
    color: #FAFAFA;
}

.mb {
    margin-bottom: 24px;
}

.left_list_block {
    width: 47%;
}

.right_list_block {
    width: 48%;
}

.line {
    display: flex;
    align-items: flex-start;
    margin-bottom: 36px;
}

.line_desc {
    width: 93%;
}

.line_title {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #FAFAFA;
    margin-left: 16px;
    margin-bottom: 12px;
}

.line_text {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #FAFAFA;
    margin-left: 16px;
    width: 570px;
}

.check_circle {
    width: 24px;
    height: 24px;
    mask-image: url("../../assets/img/check_circle.svg");
    background-color: #E7007F;
}

.gradient_block {
    background: linear-gradient(91.51deg, #122932 -6.89%, #E7007F 128.91%, #009DE0 128.91%);
    border-radius: 30px;
    padding: 64px;
    margin-bottom: 140px;
}

.open_text {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #FAFAFA;
    margin-bottom: 24px;
}

.btn_block {
    margin-top: 48px;
}

.btn_wrapper {
    min-width: 181px;
    max-width: 250px;
}

@media only screen
and (max-device-width: 768px) {

    .content {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
    }

    .title {
        font-size: 28px;
        line-height: 42px;
        margin-top: 32px;
        margin-bottom: 32px;
    }

    .who_we_desc_block {
        margin-left: 0;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 96px;
    }

    .who_we_description {
        padding-left: 14px;
        width: 100%;
        margin-bottom: 16px;
        font-size: 16px;
    }

    .flex {
        flex-direction: column-reverse;
    }

    .coins_block {
        display: flex;
        justify-content: space-between;
        margin-top: 96px;
        margin-bottom: 96px;
        width: 100%;
    }

    .coins_img_block img {
        left: 50%;
        transform: translateX(-50%);
        top: -23px;
        width: 295px;
        height: 104px;
    }

    .coins_description_block {
        height: fit-content;
        border-radius: 30px;
        padding: 84px 24px 24px;
        width: 100%;
        font-size: 16px;
    }

    .left {
        width: 100%;
        font-size: 16px;
    }

    .right {
        width: 100%;
        font-size: 16px;
        margin-bottom: 24px;
    }

    .services_list_block {
        margin-top: 96px;
        margin-bottom: 96px;
    }

    .services_list_block_title {
        font-size: 28px;
        line-height: 130%;
        margin-bottom: 24px;
        width: 100%;
    }

    .line {
        margin-bottom: 16px;
    }

    .line_desc {
        width: 93%;
    }

    .line_title {
        font-size: 16px;
        line-height: 150%;
        margin-left: 16px;
        margin-bottom: 10px;
    }

    .line_text {
        font-size: 16px;
        line-height: 150%;
        width: 90%;
    }

    .left_list_block {
        width: 100%;
    }

    .right_list_block {
        width: 100%;
    }

    .gradient_block {
        padding: 48px 24px;
        margin-bottom: 64px;
    }

    .open_text {
        font-size: 16px;
        margin-bottom: 32px;
    }

    .btn_wrapper {
        width: 100%;
    }

}
