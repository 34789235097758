.checkbox_label {
    height: 24px;
}

input[type="checkbox"] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.checkbox {
    display: inline-block;
    height: 24px;
    width: 24px;
    background: transparent;
    border: 1px solid #E7007F;
    border-radius: 8px;
    margin-right: 12px;
    cursor: pointer;
    transition: .5s all ease;
}

.checkbox:hover {
    background: #521D49;
    transition: .5s all ease;
}

.checkbox--active {
    border-color: #E7007F;
    background: #E7007F;
    position: relative;
    transition: .5s all ease;
}

.checkbox--active:before {
    content: url("../../../assets/img/check.svg");
    position: absolute;
    transition: .5s all ease;
    top: 0;
    left: 0;
}

@media only screen
and (max-device-width: 768px) {
    .checkbox {
        height: 24px;
        width: 24px;
    }

    .checkbox--active:before {
        left: 0;
        top: 0;
    }
}
