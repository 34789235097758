.myPopup {
    position: fixed;
    height: 100vh;
    width: 100%;
    background: #0D4C66;
    border-radius: 0 0 4px 4px;
    top: 0;
    right: 0;
    z-index: 12;
    color: #fff;
    padding: 16px 16px 70px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.titleBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    font-family: 'Metropolis', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
}

.close_img_block {
    display: flex;
    justify-content: flex-end;
}

.closeImg {
    cursor: pointer;
}

.active {
    margin-right: 0;
    transition: margin-right 500ms ease;
}

.inactive {
    margin-right: -100%;
    transition: margin-right 500ms ease;
}

.links_block {
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    padding-bottom: 30px;
}


.link {
    width: fit-content;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FAFAFA;
    text-decoration: none;
}

.btn_block {
    margin-bottom: 14px;
}

.mobile_menu_footer {
    position: absolute;
    bottom: 16px;
    padding-top: 24px;
    border-top: 1px solid #122932;
    width: 100%;
    left: 0;
}

.copyright {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #122932;
}

.lang_dropdown {
    max-width: 100px;
}

.dropdown_menu {
    position: absolute;
    background-color: #521D49;
    border: 1px solid #E7007F;
    color: #FAFAFA;
    min-width: 100px;
    z-index: 1;
    border-radius: 20px;
    text-align: center;
    margin-top: 8px;
}

.dropdown_menu div {
    padding: 8px;
    cursor: pointer;
}

.dropdown_menu div:hover {
    background-color: #E7007F;
    border-radius: 20px;
}
