.wrapper {
    display: flex;
    justify-content: space-between;
}

.link {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FAFAFA;
    text-decoration: none;
    position: relative;
}

.link:hover:after {
    width: 100%;
    content: "";
    bottom: -2px;
    left: 0;
    height: 2px;
    background: #009DE0;
    position: absolute;
}
