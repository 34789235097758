.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #122932;
}

.content {
    width: 1440px;
    padding: 0 100px;
    box-sizing: border-box;
    margin: 0 auto;
}

.motion_div {
    width: 100%;
}

.title {
    font-family: 'Kanit', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 96px;
    text-transform: uppercase;
    color: #FAFAFA;
    margin-top: 100px;
    margin-bottom: 60px;
}

.gradient_block {
    width: 100%;
    height: 54px;
    background: linear-gradient(91.51deg, #122932 -6.89%, #E7007F 128.91%, #009DE0 128.91%);
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #FAFAFA;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 64px;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.left {
    width: 47%;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: justify;
    color: #FAFAFA;
}

.right {
    width: 47%;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: justify;
    color: #FAFAFA;
}

.mb {
    margin-bottom: 24px;
}

.puzzles_block {
    margin-top: 140px;
    margin-bottom: 140px;
}

.puzzles_img_block {
    border-radius: 30px 0 0 30px;
    width: 412px;
    height: 580px;
    background: #0D4C66;
    margin-right: 8px;
}

.puzzles_description_block {
    width: auto;
    height: 580px;
    background: #0D4C66;
    border-radius: 0 30px 30px 0;
    padding: 88px 72px 48px 48px;
    box-sizing: border-box;
}

.puzzles_description_content {
    margin-bottom: 28px;

    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #FAFAFA;
}

.puzzles_button_block {
    margin-top: 48px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.button_wrapper {
    max-width: 250px;
}

.coin_block {
    width: 100%;
    margin-top: 208px;
    background: #521D49;
    height: 248px;
    display: flex;
    justify-content: center;
    padding: 64px 0;
    box-sizing: border-box;
    margin-bottom: 140px;
}

.text {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #FAFAFA;
    width: 820px;
    position: relative;
}

.italic {
    font-style: italic;
    font-weight: 700;
}

.coin_container {
    position: absolute;
    bottom: 50px;
    right: -308px;
}

.puzzles_mob {
    display: none;
}

@media only screen and
(max-device-width: 768px) {

    .title {
        font-family: 'Kanit', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        text-transform: uppercase;
        color: #FAFAFA;
        margin: 32px 0;
    }

    .gradient_block {
        height: 72px;
        background: linear-gradient(91.51deg, #122932 -6.89%, #E7007F 128.91%, #009DE0 128.91%);
        font-family: 'Mulish', serif;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 64px;
    }

    .puzzles {
        display: none;
    }

    .content {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
    }

    .flex {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .left {
        width: 100%;
        font-family: 'Mulish', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-align: justify;
        color: #FAFAFA;
    }

    .right {
        width: 100%;
        font-family: 'Mulish', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-align: justify;
        color: #FAFAFA;
        margin-top: 16px;
    }

    .puzzles_block {
        margin-top: 96px;
        margin-bottom: 96px;
    }

    .puzzles_img_block {
        border-radius: 30px 30px 0 0;
        width: 343px;
        height: 242px;
        margin-right: 0;
        margin-bottom: 8px;
    }

    .puzzles_mob {
        display: block;
        width: 343px;
        height: 242px;
    }

    .puzzles_description_block {
        width: auto;
        height: auto;
        background: #0D4C66;
        border-radius:0 0 30px 30px;
        padding: 32px 16px 48px;
        box-sizing: border-box;
    }

    .puzzles_description_content {
        margin-bottom: 16px;
        font-family: 'Mulish', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #FAFAFA;
    }

    .puzzles_button_block {
        margin-bottom: 0;
    }

    .coin_block {
        width: 100%;
        margin-top: 96px;
        background: #521D49;
        height: auto;
        display: flex;
        justify-content: center;
        padding: 64px 0;
        box-sizing: border-box;
        margin-bottom: 64px;
    }

    .text {
        font-family: 'Mulish', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #FAFAFA;
        position: relative;
        width: auto;
    }

    .coin_container {
        position: absolute;
        bottom: 174px;
        right: 0;
        width: 90px;
        height: 90px;
    }

    .coin_container img {
        width: 90px;
        height: 90px;
    }

    .button_wrapper {
        width: 100%;
    }
}
