.title {
    font-family: 'Kanit', serif;
    font-style: italic;
    font-weight: 275;
    font-size: 60px;
    line-height: 130%;
    color: #FAFAFA;
    margin-bottom: 80px;
    padding-top: 40px;
}

.choose_account_type_block {
    display: flex;
    justify-content: center;
}

.business_account {
    width: 209px;
    height: 248px;
    background: #FAFAFA;
    border-radius: 24px;
    margin-right: 16px;
    cursor: pointer;
}

.personal_account {
    width: 209px;
    height: 248px;
    background: #FAFAFA;
    border-radius: 24px;
    margin-left: 16px;
    cursor: pointer;
}

.active {
    border: 2px solid #009DE0;
    box-sizing: border-box;
}

.inactive {
    padding: 46px 2px 2px 2px;
    box-sizing: border-box;
}

.inactive:hover {
    border: 2px solid #009DE0;
    box-sizing: border-box;
    padding: 44px 0 0 0;
}

.circle_check_block {
    margin: 12px 12px 8px;
    position: relative;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: #009DE0;
    float: right;
}

.circle_check {
    width: 16px;
    height: 16px;
    background-color: #FAFAFA;
    mask-image: url("../../../assets/img/mini_check_circle.svg");
    position: absolute;
    top: 4px;
    left: 4px;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card_title {
    margin-top: 32px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #122932;
}


.buttons_wrapper {
    background-color: transparent;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 48px;
}

.buttons_block {
    display: flex;
    justify-content: space-between;
    width: 392px;
}

.button_next {
    width: 180px;
}

.button_back {
    width: 180px;
}

@media only screen
and (max-device-width: 768px) {

    .title {
        font-weight: 275;
        font-size: 28px;
        line-height: 130%;
        padding-top: 28px;
        margin-bottom: 48px;
    }

    .choose_account_type_block {
        padding: 0 16px;
    }


    .business_account {
        width: 167px;
        height: 208px;
        margin-right: 4px;
    }

    .personal_account {
        width: 167px;
        height: 208px;
        margin-left: 4px;
    }

    .buttons_block {
        width: 100%;
        flex-direction: column-reverse;
        gap: 20px;
        padding: 0 16px;
    }

    .button_next {
        width: 100%;
    }

    .button_back {
        width: 100%;
    }
}
