.input_wrapper {
    position: relative;
}

.input {
    padding: 17px 30px;
    width: 100%;
    box-sizing: border-box;
    background: #FAFAFA;
    border-radius: 32px;
    border: none;
    outline: none;

    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}

.input:hover {
    outline: 1px solid #E7007F;
}

.input::-webkit-input-placeholder {
    color: #ACAEAF;
}

.view {
    display: block;
}

.hidden {
    display: none;
}

.passwordView {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url(../../../assets/img/no_view.svg) 0 0 no-repeat;
}

.passwordControl {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url(../../../assets/img/view.svg) 0 0 no-repeat;
}

@media only screen
and (max-device-width: 768px) {
    .input {
        padding: 11px 16px;
        height: 40px;
    }
}
