.my_select_wrapper {
    margin: 0!important;
    border: none!important;
    background: #fff;
    border-radius: 32px;
    width: 40px;
    height: 58px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #122932;
}

.my_select {
    border: none!important;
    padding-left: 16px;
    margin: 0;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #122932;
    height: 100%;
}

.my_select_wrapper div {
    border-radius: 32px!important;
}

@media only screen
and (max-device-width: 768px) {
    .my_select_wrapper {
        height: 48px;
    }
}
