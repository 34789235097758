.change_page_icon_next {
    width: 40px;
    height: 40px;
    transition: .3s all ease;
    background-image: url("../../assets/img/next_item_arrow.svg") ;
}

.change_page_icon_prev {
    width: 40px;
    height: 40px;
    transition: .3s all ease;
    background-image: url("../../assets/img/prev_item_arrow.svg") ;
}

.change_page_icon_next:hover {
    background-image: url("../../assets/img/hovered_next_icon.svg") ;
}

.change_page_icon_prev:hover {
    background-image: url("../../assets/img/hovered_button_prev.svg") ;
}

@media only screen
and (max-device-width: 768px) {
    .change_page_icon_prev {
        width: 24px;
        height: 24px;
        background-image: url("../../assets/img/mobile/prev_icon_mobile.svg") ;
    }

    .change_page_icon_next {
        width: 24px;
        height: 24px;
        background-image: url("../../assets/img/mobile/next_icon_mobile.svg") ;
    }

    .change_page_icon_next:hover {
        background-image: url("../../assets/img/mobile/hovered_icon_next.svg") ;
    }

    .change_page_icon_prev:hover {
        background-image: url("../../assets/img/mobile/hovered_icon_prev.svg") ;
    }
}
