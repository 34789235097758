.wrapper {
    padding: 32px 100px 4px;
    box-sizing: border-box;
    width: 100%;
    background: #122932;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.buttons_block {
    display: flex;
    align-items: center;
}

.whatsapp_wrapper {
    width: 38px;
    height: 38px;
    background: #009DE0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .5s all ease;
    border: 1px solid #009DE0;
    margin-right: 20px;
}

.whatsapp_wrapper:hover {
    transition: .5s all ease;
    background: #0D4C66;
    border: 1px solid #009DE0;
    width: 38px;
    height: 38px;
}

.btn_open_block {
    margin: 0 20px;
}

.whatsapp_wrapper_mobile {
    display: none;
}

.login_btn_wrapper {
    width: 139px;
}

.mobile_flex {
    display: none;
}

.dropdown_menu {
    position: absolute;
    background-color: #521D49;
    border: 1px solid #E7007F;
    color: #FAFAFA;
    min-width: 100px;
    z-index: 1;
    border-radius: 20px;
    text-align: center;
    margin-top: 8px;
}

.dropdown_menu div {
    padding: 8px;
    cursor: pointer;
}

.dropdown_menu div:hover {
    background-color: #E7007F;
    border-radius: 20px;
}

@media only screen
and (max-device-width: 768px) {
    .content {
        margin-bottom: 0;
    }

    .navbar_wrapper {
        display: none;
    }

    .wrapper {
        padding: 14px 16px;
    }

    .buttons_block {
        display: none;
    }

    .whatsapp_wrapper_mobile {
        width: 38px;
        height: 38px;
        background: #009DE0;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: .5s all ease;
        border: 1px solid #009DE0;
        margin-right: 20px;
    }

    .mobile_flex {
        display: flex;
        align-items: center;
    }
}
