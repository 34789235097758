.card {
    width: 400px;
    height: 402px;
    background: linear-gradient(0deg, #FAFAFA 100%, #FAFAFA 100%, #FAFAFA 100%);
    border-radius: 24px;
    padding: 32px 28px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    transition: .5s all ease;
}

.increase_height {
    height: 520px;
}


.background_gradient {
    z-index: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 24px;
    padding: 32px 28px;
    box-sizing: border-box;
    background: linear-gradient(270deg, #FFFFFF 30.73%, #93D6F2 75.52%, #009DE0 100%);
    opacity: 0;
    transition: .5s all ease;
}

.card:hover .background_gradient{
    opacity: 1;
    transition: .5s all ease;
}

.card:hover .arrowBlock{
    width: 36px;
    height: 36px;
    background: #E7007F;
    border-radius: 36px;
}

.card:hover .arrow{
    width: 16px;
    height: 16px;
    mask-image: url("../../../assets/img/arrow_next.svg");
    background-color: #FAFAFA;
    transition: .5s all ease;
    transform: rotate(45deg);
}

.layout {
    position: absolute;
    z-index: 2;
    padding-right: 28px;
}

.title_block {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
}

.short_block {
    width: 60px;
    height: 58px;
    background: #521D49;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Kanit', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    color: #E7007F;
    margin-right: 20px;
}

.title {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #122932;
}

.text {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 145%;
    color: #122932;
}

.arrowBlock {
    border-radius: 50%;
    position: absolute;
    bottom: 32px;
    right: 28px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s all ease;
}

.arrow {
    width: 16px;
    height: 16px;
    mask-image: url("../../../assets/img/arrow_next.svg");
    background-color: #E7007F;
    transition: .5s all ease;
}

@media only screen
and (max-device-width: 768px) {
    .card {
        width: 100%;
        height: 402px;
        background: linear-gradient(0deg, #FAFAFA 100%, #FAFAFA 100%, #FAFAFA 100%);
        border-radius: 24px;
        padding: 32px 28px;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;
        transition: .5s all ease;
    }
    .increase_height {
        height: 402px;
    }
}

@media only screen
and (max-device-width: 425px) {
    .increase_height {
        height: 600px;
    }
}

@media only screen
and (max-device-width: 375px) {
    .card {
        height: 470px;
    }
    .title {
        font-size: 20px;
        line-height: 26px;
    }
    .increase_height {
        height: 700px;
    }
}
