.info_block {
    width: 100%;
    height: fit-content;
    background: #FAFAFA;
    border-radius: 30px;
    margin-bottom: 36px;
    padding: 32px;
}

.block_title {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #122932;
    margin-bottom: 24px;
}

.row {
    display: flex;
    margin-bottom: 12px;
}

.detail_title {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #122932;
    width: 210px;
}

.detail_value {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #0D4C66;
}

.button_block {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 82px;
}

.wrapper {
    width: 126px;
}

@media only screen
and (max-device-width: 768px) {

    .row {
        align-items: flex-start;
        justify-content: space-between;
    }

    .detail_title {
       font-size: 16px;
        width: 49%;
        word-break: break-all;
    }

    .detail_value {
        font-size: 16px;
        word-break: break-all;
        width: 49%;
    }

    .info_block {
        border-radius: 16px;
    }
}
