.my_select_wrapper {
    margin: 0!important;
    border: none!important;
    background: #fff;
    border-radius: 32px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #122932;
}

.my_select {
    border: none;
    padding: 0;
    margin: 0;
    font-family: 'Century Gothic', serif;
    height: 100%;
}

.my_select_wrapper div {
    border-radius: 32px!important;
}

.my_select_wrapper div div {
    padding-left: 30px;
}

.label {
    padding: 8px!important;
}
