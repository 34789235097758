.my_btn {
    border: 1px solid #E7007F;
    border-radius: 32px;
    padding: 16px 24px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: #FAFAFA;
    background: transparent;
    text-transform: capitalize;
    cursor: pointer;
    transition: .5s all ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.my_btn img {
    margin-right: 8px;
}

.my_btn:hover {
    transition: .5s all ease;
    background: #521D49;
}
.arrow {
    font-size: 12px;
    margin-left: 10px;
    transition: transform 0.3s ease-in-out;
    transform: scaleX(1.5) scaleY(0.7);
}
.arrow_rotated {
    transform: rotate(180deg) scaleX(1.5) scaleY(0.7);
}

@media only screen
and (max-device-width: 768px) {
    .my_btn {
        font-size: 16px;
        padding: 8px 16px;
        border-radius: 20px;
        width: 100%;
        justify-content: center;
    }
}
