.my_btn {
    border: 1px solid #E7007F;
    border-radius: 32px;
    padding: 16px 24px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: #FAFAFA;
    background: #E7007F;
    text-transform: capitalize;
    cursor: pointer;
    transition: .5s all ease;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.my_btn img {
    margin-right: 8px;
}

.my_btn:hover {
    transition: .5s all ease;
    background: #521D49;
}


@media only screen
and (max-device-width: 768px) {
    .my_btn {
        font-family: 'Mulish', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        border-radius: 20px;
        padding: 8px 16px;
        width: 100%;
        justify-content: center;
    }
}
