.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #122932;
}

.content {
    width: 1440px;
    padding: 0 100px;
    box-sizing: border-box;
    margin: 0 auto;
}

.motion_div {
    width: 100%;
}

.title {
    font-family: 'Kanit', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 96px;
    text-transform: uppercase;
    color: #FAFAFA;
    margin-top: 100px;
    margin-bottom: 60px;
}

.who_we_desc_block {
    margin-left: 105px;
    width: 820px;
    box-sizing: border-box;
    margin-bottom: 140px;
}

.btn_block {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.who_we_description {
    padding-left: 36px;
    border-left: 2px solid #009DE0;
    width: 820px;
    box-sizing: border-box;
    margin-bottom: 36px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #FAFAFA;
}

.who_we_description div {
    margin-bottom: 16px;
}

.who_we_description div:last-child {
    margin-bottom: 0;
}

.cards_block {
    margin-top: 140px;
    margin-bottom: 140px;
}

.cards_img_block {
    border-radius: 0 30px 30px 0;
    width: 412px;
    height: 580px;
    background: #0D4C66;
}

.cards_description_block {
    width: auto;
    height: 580px;
    background: #0D4C66;
    border-radius: 30px 0 0 30px;
    padding: 88px 48px 48px 72px;
    box-sizing: border-box;
    margin-right: 8px;
}

.cards_description_content {
    margin-bottom: 28px;

    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #FAFAFA;
}

.cards_button_block {
    margin-top: 48px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.cards_wrapper {
    min-width: 123px;
    max-width: 250px;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.gradient_block {
    width: 100%;
    height: 54px;
    background: linear-gradient(91.51deg, #122932 -6.89%, #E7007F 128.91%, #009DE0 128.91%);
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #FAFAFA;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 140px;
    margin-top: 140px;
}

.left {
    width: 47%;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: justify;
    color: #FAFAFA;
}

.right {
    width: 47%;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: justify;
    color: #FAFAFA;
}

.mb {
    margin-bottom: 24px;
}

.cards_mobile {
    display: none;
}


@media only screen
and (max-device-width: 768px) {

    .content {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
    }

    .title {
        font-size: 28px;
        line-height: 42px;
        margin-top: 32px;
        margin-bottom: 32px;
    }

    .who_we_desc_block {
        margin-left: 0;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 96px;
    }

    .who_we_description {
        padding-left: 14px;
        width: 100%;
        margin-bottom: 16px;
        font-size: 16px;
    }

    .flex {
        flex-direction: column-reverse;
    }

    .cards_description_block {
        width: 100%;
        height:fit-content;
        background: #0D4C66;
        border-radius:0 0 30px 30px;
        padding: 24px 16px 48px;
        box-sizing: border-box;
        margin-right: 8px;

    }

    .cards_description_content {
        margin-bottom: 16px;
        font-size: 16px;
    }

    .cards_img_block {
        border-radius:30px 30px 0 0;
        width: 100%;
        height: 242px;
        background: #0D4C66;
        margin-bottom: 8px;
    }

    .cards_mobile {
        width: 343px;
        height: 242px;
        display: block;
    }

    .cards {
        display: none;
    }

    .cards_block {
        margin-top: 96px;
        margin-bottom: 96px;
    }

    .left {
        width: 100%;
        font-size: 16px;
    }

    .right {
        width: 100%;
        font-size: 16px;
    }

    .gradient_block {
        width: 100%;
        height: fit-content;
        padding: 12px;
        font-size: 16px;
        margin-bottom: 64px;
        margin-top: 96px;
        text-align: center;
        box-sizing: border-box;
    }

    .cards_wrapper {
        width: 100%;
    }
}
