.text_block {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ACAEAF;
    display: flex;
    align-items: center;
    margin-top: 8px;
}
.text_block_password {
    display: none;
}

.activePersonalNav .text_block{
    color: #E7007F;
}

.inactivePersonalNav:hover .img_block .img_details{
    background-color: #FAFAFA;
}

.inactivePersonalNav:hover .img_block .img_statements{
    background-color: #FAFAFA;
}

.inactivePersonalNav:hover .img_block .img_transfers{
    background-color: #FAFAFA;
}

.inactivePersonalNav:hover .img_block .img_intra{
    background-color: #FAFAFA;
}

.inactivePersonalNav:hover .img_block .img_exchange{
    background-color: #FAFAFA;
}



.inactivePersonalNav:hover .text_block {
    color: #FAFAFA;
}

.img_block {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.activePersonalNav .img_block {

}

.img_details {
    width: 36px;
    height: 36px;
    background-color: #BCBEC3;
    display: block;
    mask-image: url("../assets/img/details.svg");
}

.activePersonalNav .img_details {
    background-color: #E7007F;
}

.img_statements {
    width: 36px;
    height: 36px;
    background-color: #BCBEC3;
    display: block;
    mask-image: url("../assets/img/statements.svg");
}

.activePersonalNav .img_statements {
    background-color: #E7007F;
}

.img_transfers {
    width: 36px;
    height: 36px;
    background-color: #BCBEC3;
    display: block;
    mask-image: url("../assets/img/swift.svg");
}

.activePersonalNav .img_transfers {
    background-color: #E7007F;
}

.img_intra {
    width: 36px;
    height: 36px;
    background-color: #BCBEC3;
    display: block;
    mask-image: url("../assets/img/transfer_into.svg");
}

.activePersonalNav .img_intra {
    background-color: #E7007F;
}

.img_exchange {
    width: 36px;
    height: 36px;
    background-color: #BCBEC3;
    display: block;
    mask-image: url("../assets/img/exchange.svg");
}
.activePersonalNav .img_exchange {
    background-color: #E7007F;
}
.img_lock {
    display: none;
}

@media only screen
and (max-device-width: 768px) {
    .text_block {
        font-family: 'Mulish', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 110%;
        text-align: center;
        color: #ACAEAF;
    }
    .text_block_password {
        display: block;
        font-family: 'Mulish', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 110%;
        text-align: center;
        color: #ACAEAF;
    }
    .activePersonalNav .text_block_password{
        color: #E7007F;
    }
    .activePersonalNav .img_lock {
        background-color: #E7007F;
    }

    .inactivePersonalNav:hover .img_block .img_details{
        background-color: #FAFAFA;
    }

    .img_details {
        width: 24px;
        height: 24px;
        background-color: #BCBEC3;
        display: block;
        mask-image: url("../assets/img/mobile/details.svg");
    }

    .activePersonalNav .img_details {
        background-color: #E7007F;
    }

    .img_statements {
        width: 24px;
        height: 24px;
        background-color: #BCBEC3;
        display: block;
        mask-image: url("../assets/img/mobile/statements.svg");
    }

    .activePersonalNav .img_statements {
        background-color: #E7007F;
    }

    .img_transfers {
        width: 24px;
        height: 24px;
        background-color: #BCBEC3;
        display: block;
        mask-image: url("../assets/img/mobile/transfer_out.svg");
    }

    .activePersonalNav .img_transfers {
        background-color: #E7007F;
    }

    .img_intra {
        width: 24px;
        height: 24px;
        background-color: #BCBEC3;
        display: block;
        mask-image: url("../assets/img/mobile/transfer_into.svg");
    }

    .activePersonalNav .img_intra {
        background-color: #E7007F;
    }

    .img_exchange {
        width: 24px;
        height: 24px;
        background-color: #BCBEC3;
        display: block;
        mask-image: url("../assets/img/mobile/exchange.svg");
    }

    .activePersonalNav .img_exchange {
        background-color: #E7007F;
    }
    .img_lock {
        width: 24px;
        height: 24px;
        background-color: #BCBEC3;
        display: block;
        mask-image: url("../assets/img/lock.svg");
    }
}
