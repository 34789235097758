.input_block {
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;
}

.input_title {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #122932;
    margin-bottom: 6px;
}

.my_input {
    width: 322px;
    height: 58px;
    background: #FFFFFF;
    outline: none;
    border: none;
    font-family: 'Mulish', serif;
    font-size: 16px;
    border-radius: 32px;
    padding-left: 30px;
}

.error_input {
    outline: 1px solid #FF3124;
}

.inputs_wrapper {
    display: flex;
}

.current_currency {
    width: 128px;
    height: 58px;
    background: #DDDDDD;
    padding: 17px 30px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #131313;
    border-radius: 32px;
    box-sizing: border-box;
}

.my_textarea {
    width: 666px;
    height: 137px;
    background: #FAFAFA;
    outline: none;
    border: none;
    font-family: 'Mulish', serif;
    font-size: 16px;
    border-radius: 32px;
    padding-left: 30px;
}


.terms_block {
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #131313;
    display: flex;
    align-items: center;
}

.terms_block input {
    margin-right: 12px;
}

.checked_error {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #b70000;
    cursor: pointer;
    transition: .5s all ease;
}

.pink {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #E7007F;
    margin-left: 8px;
    cursor: pointer;
}

.transfer_button_wrapper {
    width: 666px;
    display: flex;
    justify-content: flex-end;
}

.transfer_button_block {
    width: 124px;
}

.error {
    position: absolute;
    color: #FF3124;
    bottom: -20px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

@media only screen
and (max-device-width: 768px) {
    .wrapper {
        padding: 0 16px;
        margin-bottom: 120px;
    }

    .title {
        font-size: 20px;
        padding-top: 32px;
        margin-bottom: 20px;
    }

    .title:first-child {
        padding-top: 0;
    }

    .current_currency {
        width: 80px;
        padding: 11px 16px;
        font-family: 'Mulish', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        color: #122932;
        box-sizing: border-box;
        height: auto;
    }

    .input_block {
        width: 100%;
        margin-right: 8px;
        margin-bottom: 20px;
    }

    .input_block_currency {
        width: fit-content;
        margin-right: 8px;
        margin-bottom: 20px;
    }

    .my_input {
        width: 100%;
        border-radius: 32px;
        height: 40px;
        background: #FFFFFF;
        outline: none;
        border: none;
        font-family: 'Century Gothic', serif;
        font-size: 16px;
        padding: 0 30px;
        box-sizing: border-box;
    }

    .my_input_long {
        width: 100%;
        height: 40px;
    }

    .my_textarea {
        width: 100%;
    }

    .inputs_wrapper {
        flex-direction: row;
        width: 100%;
    }

    .terms_block {
        font-size: 14px;
        line-height: 130%;
    }

    .pink {
        font-size: 14px;
        line-height: 130%;
        margin-left: 4px;
        word-break: keep-all;
    }

    .transfer_button_block {
        width: 100%;
    }

    .transfer_button_wrapper {
        width: 100%;
    }
}
