.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #122932;
}

.content {
    width: 1440px;
    padding: 0 100px;
    box-sizing: border-box;
    margin: 0 auto;
}

.main_screen {
    height: 1016px;
    position: relative;
}

.main_bg {
    top: 200px;
    left: -100px;
    position: absolute;
}

.main_title_block {
    position: absolute;
    top: 80px;
    right: 0;
    width: 793px;
    text-align: center;
}

.trusted {
    font-family: 'Kanit', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 82px;
    line-height: 123px;
    text-transform: uppercase;
    color: #FAFAFA;
    text-align: left;
}

.partner {
    position: absolute;
    font-family: 'Kanit', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 82px;
    line-height: 123px;
    text-transform: uppercase;
    color: #FAFAFA;
    margin-left: 222px;
    top: 79px;
    width: fit-content;
}

.motion_div {
    width: 100%;
}

.for_your {
    position: absolute;
    font-family: 'Kanit', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 82px;
    line-height: 123px;
    text-transform: uppercase;
    color: #FAFAFA;
    left: 100px;
    top: 158px;
    width: fit-content;
}

.for_your img {
    margin-left: 10px;
}

.digital_journey {
    position: absolute;
    font-family: 'Kanit', serif;
    font-style: italic;
    font-weight: 275;
    font-size: 76px;
    line-height: 114px;
    text-transform: uppercase;
    color: #FAFAFA;
    top: 237px;
    left: 183px;
}
.small_font {
    font-size: 60px;
}

.my_input_block {
    position: absolute;
    right: 0;
    top: 425px;
}

.my_input {
    width: 454px;
    height: 58px;
    background: #FAFAFA;
    border-radius: 32px;
    border: 0;
    outline: none;
    padding-left: 30px;
    box-sizing: border-box;

    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #122932;
}

.main_btn_block {
    position: absolute;
    right: -1px;
    top: -1px;
}

.who_we {
    display: flex;
    width: 100%;
    height: 712px;
    background: #0D4C66;
    justify-content: center;
}

.who_title {
    font-family: 'Kanit', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 96px;
    text-transform: uppercase;
    color: #FAFAFA;
    margin-top: 88px;
    margin-bottom: 60px;
}

.who_we_desc_block {
    margin-left: 105px;
    width: 820px;
    box-sizing: border-box;
}

.btn_block {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.who_we_description {
    padding-left: 36px;
    border-left: 2px solid #009DE0;
    width: 820px;
    box-sizing: border-box;
    margin-bottom: 36px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #FAFAFA;
}

.who_we_description div {
    margin-bottom: 16px;
}

.who_we_description div:last-child {
    margin-bottom: 0;
}

.color {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
}

.cards_wrapper {
    display: flex;
    flex-wrap: wrap;
}

.cards_title {
    margin-top: 140px;
    margin-bottom: 84px;
    width: 100%;
    text-align: center;
    font-family: 'Kanit', serif;
    font-style: italic;
    font-weight: 200;
    font-size: 60px;
    line-height: 130%;
    color: #FAFAFA;
}

.card_block {
    margin-right: 20px;
    margin-bottom: 20px;
}

.card_block:nth-child(4) {
    margin-right: 0;
}

.open_acc_block {
    margin-top: 184px;
    height: 432px;
    background: linear-gradient(91.51deg, #122932 -6.89%, #E7007F 128.91%, #009DE0 128.91%);
    border-radius: 30px;
    margin-bottom: 140px;
    padding: 64px;
    box-sizing: border-box;
    position: relative;
}

.open_account_title {
    margin-bottom: 24px;
    font-family: 'Kanit', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 96px;
    text-transform: uppercase;
    color: #FAFAFA;
}

.open_account_desc {
    margin-bottom: 48px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 145%;
    color: #FAFAFA;
    width: 516px;
}

.italic {
    font-family: 'Mulish', serif;
    font-style: italic;
    font-weight: 700;
}

.phone {
    position: absolute;
    bottom: 0;
    right: 52px;
}

.main_screen_mobile {
    display: none;
}

.open_account_desc_mobile {
    display: none;
}

.btn_open_account {
    min-width: 181px;
    max-width: 250px;
}


@media only screen
and (max-device-width: 768px) {
    .main_bg {
        display: none;
    }

    .content {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
        height: auto;
    }

    .who_we_desc_block {
        margin-left: 0;
        width: auto;
        box-sizing: border-box;
    }

    .who_we_description {
        padding-left: 12px;
        border-left: 2px solid #009DE0;
        width: auto;
        box-sizing: border-box;
        margin-bottom: 36px;
        font-family: 'Mulish', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        color: #FAFAFA;
    }

    .open_account_desc_mobile {
        display: block;
        width: auto;
        margin-bottom: 32px;
        font-size: 16px;
        font-family: 'Mulish', serif;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        color: #FAFAFA;
    }

    .open_account_desc {
        display: none;
    }

    .open_acc_block {
        margin-top: 96px;
        height: 402px;
        background: linear-gradient(91.51deg, #122932 -6.89%, #E7007F 128.91%, #009DE0 128.91%);
        border-radius: 30px;
        margin-bottom: 64px;
        box-sizing: border-box;
        position: relative;
        padding: 44px 0 64px 24px;
    }

    .cards_wrapper {
        display: flex;
        flex-wrap: initial;
        flex-direction: column;
    }

    .card_block {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .who_we {
        display: flex;
        width: 100%;
        height: fit-content;
        background: #0D4C66;
        justify-content: center;
        padding-bottom: 48px;
    }

    .main_screen {
        display: none;
    }

    .main_screen_mobile {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding-top: 34px;
    }

    .title_block {

    }

    .title_mobile {
        font-family: 'Kanit', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 46px;
        line-height: 38px;
        text-transform: uppercase;
        color: #FAFAFA;
        display: flex;
        align-items: center;
    }

    .title_mobile_margin {
        font-family: 'Kanit', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 46px;
        line-height: 46px;
        text-transform: uppercase;
        color: #FAFAFA;
        margin-left: 103px;
    }

    .arrow_main_screen_mobile {
        margin-left: 24px;
        width: 40px;
        height: 40px;
    }

    .title_italic {
        font-family: 'Kanit', serif;
        font-style: italic;
        font-weight: 275;
        font-size: 43px;
        line-height: 130%;
        text-transform: uppercase;
        color: #FAFAFA;
    }

    .main_img_mobile {
        margin-left: -16px;
        width: 100%;
    }

    .main_img_mobile_block {
        width: 100%;
    }

    .input_wrapper {
        position: relative;
        width: 100%;
        height: 40px;
        margin-top: 35px;
        margin-bottom: 96px;
    }

    .my_input_block {
        position: absolute;
        right: initial;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .my_input {
        width: 100%;
        height: 100%;
        background: #FAFAFA;
        border-radius: 32px;
        border: 0;
        outline: none;
        padding-left: 30px;
        box-sizing: border-box;
        font-family: 'Mulish', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #122932;
    }

    .who_title {
        font-family: 'Kanit', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        text-transform: uppercase;
        color: #FAFAFA;
        margin-top: 48px;
        margin-bottom: 36px;
    }

    .who_we_description {
        font-family: 'Mulish', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #FAFAFA;
    }

    .color {
        font-weight: 700;
        font-size: 16px;
    }

    .cards_title {
        margin-top: 96px;
        margin-bottom: 36px;
        font-weight: 275;
        font-size: 28px;
        line-height: 130%;
        color: #FAFAFA;
        text-align: left;
    }

    .open_account_title {
        font-family: 'Kanit', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        text-transform: uppercase;
        color: #FAFAFA;
        margin-bottom: 36px;
    }

    .btn_open_account {
        min-width: 140px;
        max-width: 150px;
        z-index: 2;
        position: absolute;
    }

    .phone {
        width: 259px;
        height: 229px;
        right: -16px;
        z-index: 0;
    }
}

@media only screen
and (max-device-width: 425px) {
    .phone {
        width: 220px;
        height: 190px;
        right: -16px;
    } 
}
@media only screen
and (max-device-width: 320px) {
    .phone {
        width: 170px;
        height: 140px;
        right: -30px;
    } 
}
