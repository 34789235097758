.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #122932;
    width: 100%;
}

.content {
    width: 1440px;
    padding: 0 100px;
    box-sizing: border-box;
    margin: 0 auto;
}

.motion_div {
    width: 100%;
}

.title {
    font-family: 'Kanit', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 96px;
    text-transform: uppercase;
    color: #FAFAFA;
    margin-top: 100px;
    margin-bottom: 60px;
}

.who_we_desc_block {
    margin-left: 105px;
    width: 820px;
    box-sizing: border-box;
}

.who_we_description {
    padding-left: 36px;
    border-left: 2px solid #009DE0;
    width: 820px;
    box-sizing: border-box;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #FAFAFA;
}

.who_we_description div {
    margin-bottom: 16px;
}

.who_we_description div:last-child {
    margin-bottom: 0;
}

.white_bold {
    font-style: italic;
    font-weight: 700;
}

.services_list_block {
    margin-top: 140px;
    margin-bottom: 48px;
}

.services_list_block_title {
    font-family: 'Kanit', serif;
    font-style: italic;
    font-weight: 275;
    font-size: 60px;
    line-height: 130%;
    color: #FAFAFA;
    margin-bottom: 48px;
    width: 75%;
}


.coins_block {
    display: flex;
    justify-content: space-between;
    margin-top: 140px;
    margin-bottom: 140px;
    background: #521D49;
    border-radius: 30px;
}

.coins_img_block {
    border-radius: 30px 0 0 30px;
    position: relative;
    width: 100%;
}

.p2p_money {
    position: absolute;
    right: -58px;
    top: 0;
}

.card_title {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #122932;
    margin-bottom: 10px;
}

.coins_description_block {
    height: 278px;
    border-radius: 0 30px 30px 0;
    padding: 64px 64px 64px 50px;
    box-sizing: border-box;
    width: 630px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #FAFAFA;
}

.coins_description_block div {
    margin-bottom: 28px;

    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #FAFAFA;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.flex {
    display: flex;
    justify-content: space-between;
}


.left {
    width: 45%;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: justify;
    color: #FAFAFA;
}

.right {
    width: 45%;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: justify;
    color: #FAFAFA;
}

.mb {
    margin-bottom: 24px;
}

.left_list_block {
    width: 47%;
}

.right_list_block {
    width: 48%;
}

.line {
    display: flex;
    align-items: flex-start;
    margin-bottom: 36px;
}

.line_desc {
    width: 93%;
}

.line_title {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #FAFAFA;
    margin-left: 16px;
    margin-bottom: 12px;
}

.check_circle {
    width: 24px;
    height: 24px;
    mask-image: url("../../assets/img/check_circle.svg");
    background-color: #E7007F;
}

.gradient_block {
    background: linear-gradient(91.51deg, #122932 -6.89%, #E7007F 128.91%, #009DE0 128.91%);
    border-radius: 30px;
    padding: 64px;
    margin-bottom: 140px;
}

.open_text {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #FAFAFA;
    margin-bottom: 24px;
}

.btn_block {
    margin-top: 48px;
}

.color_background {
    background: #0D4C66;
    padding: 64px 100px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 140px;
}

.check_circle {
    width: 24px;
    height: 24px;
    mask-image: url("../../assets/img/check_circle.svg");
    background-color: #E7007F;
}

.services_list_block_title {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #FAFAFA;
    width: 60%;
    margin-bottom: 48px;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.left {
    width: 47%;
}

.right {
    width: 48%;
}

.line {
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
}

.line_text {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #FAFAFA;
    margin-left: 16px;
    width: 570px;
}

.line_text_short {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #FAFAFA;
    margin-left: 16px;
    width: fit-content;
}

.cards_wrapper {
    margin-bottom: 140px;
}

.card_01 {
    background: #FAFAFA;
    border-radius: 30px;
    width: 610px;
    height: 570px;
    margin-bottom: 20px;
    padding: 36px 58px 56px 36px;
    box-sizing: border-box;
    position: relative;
}

.cash_block {
    position: absolute;
    bottom: -4px;
    right: 11px;
}

.card_02 {
    background: linear-gradient(221.54deg, #E7007F 0%, #521D49 93.29%);
    border-radius: 30px;
    width: 610px;
    height: 570px;
    padding: 36px 58px 56px 36px;
    box-sizing: border-box;
    position: relative;
}

.increase_height {
    height: 630px;
}

.card_03 {
    position: relative;
    display: flex;
    justify-content: space-between;
    background: #521D49;
    border-radius: 30px;
    width: 100%;
    /* height: 450px; */
    padding: 36px 58px 56px 36px;
    box-sizing: border-box;
}

.coins_block {
    position: absolute;
    top: 0;
    right: 0;
}

.card_03 .card_number {
    color: #FAFAFA;
}

.card_02 .card_number {
    color: #FAFAFA;
}

.card_02 .card_text {
    color: #FAFAFA;
}

.card_03 .card_text {
    color: #FAFAFA;
    width: 671px;
}

.card_02 .card_title {
    color: #FAFAFA;
}

.card_03 .card_title {
    color: #FAFAFA;
}

.card_number {
    font-family: 'Kanit', serif;
    font-style: italic;
    font-weight: 275;
    font-size: 60px;
    line-height: 130%;
    color: #122932;
    margin-bottom: 10px;
}

.card_text {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #122932;
    margin-bottom: 10px;
}

.check_circle_blue {
    width: 24px;
    height: 24px;
    mask-image: url("../../assets/img/check_circle.svg");
    background-color: #009DE0;
}


.gradient {
    background: linear-gradient(270deg, #FFFFFF 30.73%, #93D6F2 75.52%, #009DE0 100%);
    border-radius: 30px;
    width: auto;
    padding: 64px;
    margin-top: 140px;
    margin-bottom: 140px;
}

.gradient_title {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #122932;
    margin-bottom: 48px;
}

.gradient_text {
    color: #122932;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    margin-left: 16px;
    width: 93%;
}

.gradient_footer {
    margin-top: 48px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #122932;
}

.bold {
    font-weight: 700;
    font-size: 24px;
}

.bold_italic {
    font-style: italic;
    font-weight: 700;
}

.p2p_money_mobile {
    display: none;
}

.short {
    width: 233px;
    margin-right: 36px;
}

.short_block {
    width: 70%;
    justify-content: initial;
}

.right_short_block {
    width: 530px;
}

@media only screen
and (max-device-width: 768px) {
    .content {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
    }

    .title {
        font-size: 28px;
        line-height: 42px;
        margin-top: 32px;
        margin-bottom: 32px;
    }

    .who_we_desc_block {
        margin-left: 0;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 0;
    }

    .who_we_description {
        padding-left: 14px;
        width: 100%;
        margin-bottom: 16px;
        font-size: 16px;
    }

    .flex {
        flex-direction: column;
        width: 100%;
    }

    .color_background {
        background: #0D4C66;
        padding: 48px 16px;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 96px;
    }

    .left {
        width: 100%;
        font-size: 16px;
        text-align: left;
    }

    .right {
        width: 100%;
        font-size: 16px;
        text-align: left;
    }

    .services_list_block_title {
        font-size: 20px;
        width: 100%;
        margin-bottom: 32px;
    }

    .line_text {
        font-size: 16px;
        margin-left: 16px;
        width: 90%;
    }

    .cards_wrapper {
        margin-bottom: 96px;
    }

    .services_list_block {
        margin-bottom: 0;
        margin-top: 96px;
    }

    .card_01 {
        width: 100%;
        height: fit-content;
        margin-bottom: 20px;
        padding: 24px;
    }

    .cash_block {
        position: absolute;
        bottom: -4px;
        right: 11px;
    }

    .card_02 {
        width: 100%;
        height: fit-content;
        margin-bottom: 20px;
        padding: 24px;
    }

    .card_03 {
        width: 100%;
        height: fit-content;
        margin-bottom: 20px;
        padding: 24px;
        flex-direction: column;
    }

    .card_number {
        font-weight: 275;
        font-size: 28px;
        line-height: 130%;
    }

    .card_text {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 10px;
        width: 100%;
    }

    .card_title {
        font-size: 20px;
    }

    .card_03 .card_text {
        width: 100%;
    }

    .p2p_money {
        display: none;
    }

    .p2p_money_mobile {
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .coins_img_block {
        height: 167px;
    }

    .gradient {
        padding: 24px 16px;
        margin-top: 0;
        margin-bottom: 64px;
    }

    .gradient_title {
        font-size: 20px;
        margin-bottom: 24px;
    }

    .gradient_text {
        font-size: 16px;
    }

    .gradient_footer {
        margin-top: 8px;
        font-size: 16px;
    }

    .bold {
        font-size: 16px;
    }

    .line {
        margin-bottom: 16px;
    }
}
