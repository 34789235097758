.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    width: 820px;
}

.title {
    font-family: 'Kanit', serif;
    font-style: italic;
    font-weight: 275;
    font-size: 60px;
    line-height: 130%;
    color: #FAFAFA;
    margin-bottom: 24px;
    margin-top: 80px;
}

.title:first-child {
    margin-top: 40px;
}

.subtitle {
    margin-bottom: 40px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    color: #FAFAFA;
}

.inputs_row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.inputs_row_currency {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
}

.input_block {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.buttons_wrapper {
    width: 100%;
    background-color: #122932;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttons_block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 82px;
    gap: 20px;
}

.button_back {
    width: 180px;
}

.button_next {
    width: 180px;
}

.describe {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #ACAEAF;
    margin-bottom: 8px;
    text-align: left;
}

.flex {
    display: flex;
    align-items: center;
}

.flex_label {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 32px;
}

.custom_checkbox {
    width: 30px;
    height: 30px;
    border: 1px solid #E7007F;
    border-radius: 8px;
    background-color: #122932!important;
}

.currency {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #FAFAFA;
    margin-left: 12px;
}

.rules {
    margin-top: 80px;
    margin-bottom: 32px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #ACAEAF;
    text-align: left;
}

.input_file_block {
    position: relative;
}

.hidden_input {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.attach_button_block {
    position: absolute;
    right: -1px;
    top: -1px;
    z-index: 10;
}

@media only screen
and (max-device-width: 768px) {
    .content {
        width: 91vw;
        padding: 0 16px;
    }

    .title {
        font-size: 28px;
        margin-bottom: 16px;
        margin-top: 28px;
    }

    .title:first-child {
        margin-top: 28px;
    }

    .subtitle {
        margin-bottom: 32px;
        font-size: 16px;
        line-height: 150%;
    }

    .inputs_row {
        flex-direction: column;
        min-height: 95px;
        margin-bottom: 16px;
    }

    .inputs_row_last {
        min-height: fit-content;
    }

    .input_block {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .inputs_row_currency {
        flex-direction: column;
    }

    .flex_label {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 25px;
    }

    .describe {
        margin-top: 16px;
    }

    .buttons_block {
        display: flex;
        justify-content: space-between;
        margin-bottom: 82px;
        flex-direction: column-reverse;
        width: 100%;
        padding: 0 16px;
    }

    .button_back {
        margin-right: 0;
        margin-bottom: 20px;
        width: 100%;
    }

    .button_next {
        margin-left: 0;
        width: 100%;
    }

    .margin_mobile {
        margin-bottom: 16px;
    }

    .rules {
        margin-top: 40px;
        margin-bottom: 32px;
    }
}
