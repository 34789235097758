.ant-steps-item-icon {
    background-color: #0D4C66!important;
    border: 1px solid #009DE0!important;
    margin-inline-end: 0!important;
}

.ant-steps-item-active .ant-steps-item-icon {
    background-color: #009DE0!important;
}

.ant-steps-icon {
    color: #fff!important;
}

.ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
    padding-inline-end: 0!important;
    padding-inline-start: 0!important;
}

.ant-steps-item {
    padding-inline-start: 0!important;
}
