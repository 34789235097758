.wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 594px;
    height: 533px;
    background: #FAFAFA;
    border-radius: 30px;
    padding: 60px 16px;
    box-sizing: border-box;
}

.check {
    width: 99px;
    height: 99px;
    margin-bottom: 24px;
    color: #E7007F;

}

.title {
    font-family: 'Kanit', serif;
    font-style: italic;
    font-weight: 275;
    font-size: 60px;
    line-height: 130%;
    color: #122932;
    margin-bottom: 24px;
}

.subtitle {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #122932;
    margin-bottom: 40px;
    text-align: center;
}

.btns_block {
    display: flex;
    justify-content: center;
    width: 154px;
}

.my_btn_no {
    width: 116px;
    height: 48px;
    background: transparent;
    border: none;
    cursor: pointer;
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #131313;
}

.my_btn_yes {
    width: 116px;
    height: 48px;
    background: #E2B778;
    border: none;
    cursor: pointer;
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    transition: .5s all ease;
}

.my_btn_yes:hover {
    background: #D6AD72;
    transition: .5s all ease;
}
