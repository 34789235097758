.steps_block {
    width: 145px;
}

.center {
    height: fit-content;
}

.motion_div {
    height: calc(100vh - 217px);
    background-color: #122932;
    display: flex;
    justify-content: center;
    align-items: center;
}

.motion_div_scrolled {
    height: fit-content;
    background-color: #122932;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 217px);
}

.steps_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height:auto;
}

.buttons_wrapper {
    width: 100%;
    background-color: #122932;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
}

.buttons_block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 82px;
}

.button_next {
    padding-left: 16px;
}

.button_back {
    padding-right: 16px;
}

@media only screen
and (max-device-width: 768px) {
    .steps_wrapper {
        padding-top: 80px;
    }

    .motion_div {
        height: calc(100vh - 141px);
    }
}


