.wrapper {
    width: 100%;
    height: fit-content;
    background: #122932;
}

.container {
    padding: 40px 16px 78px;
}

.motion_div {
    width: 100%;
}

.title {
    font-family: 'Kanit', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    text-transform: uppercase;
    color: #FAFAFA;
    margin-bottom: 32px;
}

.docs_block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.doc_link {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #009DE0;
    margin-bottom: 28px;
    width: fit-content;
    position: relative;
    text-decoration: none;
}

.doc_link:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background: #009DE0;
    bottom: -2px;
    left: 0;
    border-radius: 2px;
}


.license_number {
    font-family: 'Manrope', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.19em;
    color: #FFFFFF;
    margin-left: 5px;
    margin-bottom: 8px;
}

.mwali_link {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    color: #009DE0;
    text-decoration: none;
}

.logo {
    width: 83px;
    height: 134px;
}

.mwali_block {
    display: flex;
    flex-direction: column;
    width: 91px;
    align-items: center;
    margin-top: 4px;
}
