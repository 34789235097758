.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #122932;
}

.content {
    width: 1440px;
    padding: 0 100px;
    box-sizing: border-box;
    margin: 0 auto;
}

.motion_div {
    width: 100%;
}

.title {
    font-family: 'Kanit', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 96px;
    text-transform: uppercase;
    color: #FAFAFA;
    margin-top: 100px;
    margin-bottom: 60px;
}

.cards_wrapper {
    margin-bottom: 140px;
}

.card_01 {
    background: #FAFAFA;
    border-radius: 30px;
    width: 610px;
    height: 570px;
    margin-bottom: 20px;
    padding: 36px 58px 56px 36px;
    box-sizing: border-box;
    position: relative;
}

.cash_block {
    position: absolute;
    bottom: -4px;
    right: 11px;
}

.card_02 {
    background: linear-gradient(221.54deg, #E7007F 0%, #521D49 93.29%);
    border-radius: 30px;
    width: 610px;
    height: 570px;
    padding: 36px 58px 56px 36px;
    box-sizing: border-box;
    position: relative;
}

.card_03 {
    position: relative;
    display: flex;
    justify-content: space-between;
    background: #521D49;
    border-radius: 30px;
    width: 100%;
    /* height: 450px; */
    padding: 36px 58px 56px 36px;
    box-sizing: border-box;
}

.coins_block {
    position: absolute;
    top: 0;
    right: 0;
}

.card_03 .card_number {
    color: #FAFAFA;
}

.card_02 .card_number {
    color: #FAFAFA;
}

.card_02 .card_text {
    color: #FAFAFA;
}

.card_03 .card_text {
    color: #FAFAFA;
    width: 671px;
}

.card_number {
    font-family: 'Kanit', serif;
    font-style: italic;
    font-weight: 275;
    font-size: 60px;
    line-height: 130%;
    color: #122932;
    margin-bottom: 10px;
}

.card_text {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #122932;
    margin-bottom: 10px;
}

.services_list_block {

}

.services_list_block_title {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #FAFAFA;
    width: 60%;
    margin-bottom: 48px;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.left {
    width: 47%;
}

.right {
    width: 48%;
}

.line {
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
}

.line_text {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #FAFAFA;
    margin-left: 16px;
    width: 570px;
}

.gradient {
    background: linear-gradient(270deg, #FFFFFF 30.73%, #93D6F2 75.52%, #009DE0 100%);
    border-radius: 30px;
    width: auto;
    padding: 64px;
    margin-top: 140px;
    margin-bottom: 140px;
}

.gradient_title {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #122932;
    margin-bottom: 48px;
}

.gradient_text {
    color: #122932;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    margin-left: 16px;
    width: 93%;
}

.gradient_footer {
    margin-top: 24px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #122932;
}

.check_circle {
    width: 24px;
    height: 24px;
    mask-image: url("../../assets/img/check_circle.svg");
    background-color: #009DE0;
}

.break_desktop {
    display: none;
}

@media only screen
and (max-device-width: 768px) {

    .content {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
    }

    .title {
        font-size: 28px;
        line-height: 42px;
        margin-top: 32px;
        margin-bottom: 32px;
    }

    .cards_wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 96px;
    }

    .card_01 {
        width: 100%;
        height: fit-content;
        margin-bottom: 16px;
        padding: 24px;
        border-radius: 20px;
    }

    .card_03 {
        border-radius: 20px;
        width: 100%;
        height: fit-content;
        padding: 24px 24px 255px 24px;
    }

    .card_03 .card_text {
        width: 100%;
    }

    .coins_block {
        top: initial;
        bottom: 0;
    }

    .coins_block img {
        width: 343px;
        height: 254px;
    }

    .mobile_card_2 img {
        width: 343px;
        height: 127px;
    }

    .mobile_card_2 .card_number {
        color: #FAFAFA;
    }

    .mobile_card_2 .card_text {
        color: #FAFAFA;
    }

    .card_02 {
        display: block;
        width: 100%;
        height: fit-content;
        padding: 24px;
        background: linear-gradient(221.54deg, #009DE0 0%, #122932 93.29%);
        border-radius: 20px;
        box-sizing: border-box;
        position: relative;
        margin-bottom: 16px;
    }

    .card_number {
        font-weight: 275;
        font-size: 28px;
        line-height: 130%;
        color: #122932;
        margin-bottom: 10px;
    }

    .card_text {
        font-size: 16px;
        margin-bottom: 0;
    }

    .cash_block {
        position: absolute;
        bottom: -4px;
        right: 0;
    }

    .cash_block img  {
        width: 210px;
        height: 139px;
    }

    .card_bold {
        font-size: 16px;
        width: 100px;
    }

    .services_list_block_title {
        font-size: 20px;
        width: 100%;
        margin-bottom: 32px;
    }

    .flex {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .left {
        width: 100%;
    }

    .right {
        width: 100%;
    }

    .line_text {
        font-size: 16px;
        width: 88%;
    }

    .gradient {
        width: auto;
        padding: 24px 16px;
        margin-top: 96px;
        margin-bottom: 64px;
    }

    .gradient_title {
        font-size: 20px;
        margin-bottom: 24px;
    }

    .gradient_text {
        font-size: 16px;
    }

    .gradient_footer {
        font-size: 16px;
        margin-top: 8px;
    }

    .line {
        margin-bottom: 16px;
    }
}
