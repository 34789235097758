.header_wrapper {
    background: #ECECEC;
}

.logo {
    margin-top: 20px;
    margin-bottom: 76px;
}

.nav_item {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    cursor: pointer;
    margin-bottom: 16px;
    padding: 12px 0;
}

.layout {
    z-index: 3;
}

.img_block {
    width: 74px;
    height: 100%;
}

.all_rights {
    position: absolute;
    bottom: 24px;
    left: 32px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #0D4C66;
}

.left {
    color: #000;
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.right {
    color: #000;
    height: 32px;
    display: flex;
    align-items: center;
}

.dropdown_language {
    display: flex;
    align-items: center;
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #131313;
    cursor: pointer;
    margin-right: 20px;
}

.welcome_block {
    margin-right: 20px;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: #122932;
}

.personal_name {
    text-transform: capitalize;
}

.chat_icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 20px;
}

.btn_block {
    display: flex;
    align-items: center;
    padding: 6px 0;
}

.wrapper_dekstop {
    display: block;
}

.wrapper_mobile {
    display: none;
}

@media only screen
and (max-device-width: 768px) {
    .wrapper_dekstop {
        display: none;
    }

    .wrapper_mobile {
        display: block;
    }

    .logo_block_mobile {
        height: 100%;
        background: #122932;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 8px 10px 16px;
        margin-right: 8px;
    }

    .logo_mob {
        width: 54px;
        height: 32px;
    }

    .user_name_mobile {
        font-family: 'Mulish', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #122932;
        margin-right: 8px;
    }

    .flex {
        display: flex;
        align-items: center;
    }

    .btn_block {
        display: flex;
        align-items: center;
        padding: 6px 0;
    }
}
