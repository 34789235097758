.active {
    color: #009DE0;
    position: relative;
    width: fit-content;
}

.active:after {
    width: 100%;
    content: "";
    bottom: -2px;
    left: 0;
    height: 2px;
    background: #009DE0;
    position: absolute;
}
