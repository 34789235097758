.wrapper {
    background: #122932;
    width: 100%;
    height: calc(100vh - 217px);
    display: flex;
    justify-content: center;
}

.motion_div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    margin-top: 64px;
    background: linear-gradient(221.54deg, #009DE0 0%, #122932 93.29%);
    border-radius: 30px;
    width: 575px;
    height: 578px;
    padding: 60px 140px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    z-index: 999999;
    position: relative;
}

.error {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 14px;
    background: #ed4337;
    color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 20px;
    white-space: nowrap;
}

.error img {
    cursor: pointer;
}

.error_text {
  position: absolute;
  color: #FFA500;
  left: 0;
  bottom: -20px;
}

.title {
    font-family: 'Kanit', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 96px;
    text-transform: uppercase;
    color: #FAFAFA;
    margin-bottom: 48px;
    text-align: center;
}

.login_input_block {
    margin-bottom: 30px;
    position: relative;
}

.password_input_block {
    margin-bottom: 20px;
    position: relative;
}

.link_block {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #FAFAFA;
    margin-bottom: 48px;
    justify-content: center;
}
.forgot_password {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #B5B5B5;
    margin-bottom: 10px;
    justify-content: center;
}

.link {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: #009DE0;
    margin-left: 4px;
}

.btn_block {
    width: 180px;
    margin: 0 auto;
}

@media only screen
and (max-device-width: 768px) {
    .content {
        margin-top: 64px;
        background: linear-gradient(221.54deg, #009DE0 0%, #122932 93.29%);
        border-radius: 30px;
        width: 575px;
        height: fit-content;
        padding: 48px 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        z-index: 1;
    }

    .title {
        font-size: 40px;
        margin-bottom: 40px;
        text-align: center;
        line-height: 60px;
    }

    .wrapper {
        height: calc(100vh - 141px);
    }

    .btn_block {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
