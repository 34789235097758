.ant-pagination-item-active {
    background-color: #521D49!important;
    border-color: #521D49!important;
    border-radius: 50%!important;
}

.ant-pagination-item-active a {
    color: #FFFFFF!important;
}

.ant-pagination-item {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    width: 38px!important;
    height: 38px!important;
    display: flex!important;
    justify-content: center;
    align-items: center;
}

.ant-pagination-item:hover {
    border-radius: 50%;
    background-color: #ACAEAF;
}

.paginator_wrapper {
    width: 100%;
}

.ant-pagination {
    display: flex;
    justify-content: flex-end;
}



.ant-pagination-total-text {
    position: absolute;
    color: #122932;
    left: 32px;
    font-size: 16px;
    line-height: 150%;
}



@media only screen
and (max-device-width: 768px) {
    .ant-pagination-total-text {
        left: 16px;
        font-family: 'Mulish', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px!important;
    }

    .ant-pagination li {
        height: 24px!important;
    }

    .ant-pagination-item {
        font-family: 'Mulish', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        width: 24px!important;
        min-width: 24px!important;
        height: 24px!important;
        display: flex!important;
        justify-content: center;
        align-items: center;
    }
}
