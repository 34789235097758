.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 116px;
}

.content {
    width: 682px;
}

.title {
    font-family: 'Kanit', serif;
    font-style: italic;
    font-weight: 275;
    font-size: 60px;
    line-height: 130%;
    color: #FAFAFA;
    margin-bottom: 24px;
    margin-top: 80px;
}

.title:first-child {
    margin-top: 40px;
}

.subtitle {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    color: #FAFAFA;
}


.buttons_wrapper {
    width: 100%;
    background-color: #122932;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
}

.buttons_block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 82px;
}

@media only screen
and (max-device-width: 768px) {


    .content {
        width: 100%;
    }

    .subtitle {
        font-size: 16px;
        padding: 0 16px;
    }

    .title {
        font-size: 28px;
    }

    .buttons_block {
        width: 100%;
    }

    .button_next {
        width: 100%;
        padding: 0 16px;
    }

    .buttons_wrapper {
        padding-top: 56px;
    }
}
