.my_select_wrapper {
    margin: 0!important;
    border: none!important;
    background: #fff;
    border-radius: 32px;
    font-family: 'Century Gothic', serif;
    min-width: 100%!important;
}

.my_select {
    border: none;
    padding: 0;
    margin: 0;
    font-family: 'Century Gothic', serif;
    height: 100%;
}

.my_select_wrapper div {
    border-radius: 32px;
}

.my_select_wrapper div div {
    text-align: left;
    padding-left: 30px;
}

.label {
    padding: 8px!important;
    color: #ACAEAF!important;
}

@media only screen
and (max-device-width: 768px) {
    .my_select_wrapper label{
        top: -6px!important;
    }
}
