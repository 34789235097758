.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #122932;
    width: 100%;
}

.content {
    width: 1440px;
    padding: 0 100px;
    box-sizing: border-box;
    margin: 0 auto;
}

.motion_div {
    width: 100%;
}

.title {
    font-family: 'Kanit', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 96px;
    text-transform: uppercase;
    color: #FAFAFA;
    margin-top: 100px;
    margin-bottom: 60px;
}

.contacts_flex_block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.left {
    width: 35%;
}

.text {
    margin-bottom: 48px;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #FAFAFA;
}

.mail_block {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.mail_block img {
    margin-right: 12px;
}

.address_block {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    gap: 12px;
    max-width: 280px;
}

.mail {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #FAFAFA;
    text-decoration: none;
}

.flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.short_input {
    width: 295px;
}

.contact_form {
    width: 50%;
    margin-bottom: 127px;
}

.mobile_additional_information {
    display: none;
}

@media only screen
and (max-device-width: 768px) {
    .content {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
        margin: 0 auto;
    }

    .title {
        font-size: 28px;
        margin-bottom: 32px;
    }

    .contacts_flex_block {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
    }

    .left {
        width: 100%;
    }

    .contact_form {
        width: 100%;
        margin-bottom: 127px;
    }

    .text {
        margin-bottom: 32px;
        font-size: 16px;
    }

    .mobile_additional_information {
        display: block;
        margin-bottom: 32px;
    }

    .additional_content {
        font-family: 'Mulish', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #FAFAFA;
        margin-bottom: 12px;
    }

}

