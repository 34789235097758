.date_picker {
    height: 100%;
}

.field_style {
    height: 58px;
    background: #FFF;
    border-radius: 32px!important;
}

.field_style label {
    padding-left: 16px;
    color: #ACAEAF!important;
}

.field_style div {
    height: 58px;
    border-radius: 32px!important;
}

.field_style div input {
    padding-left: 30px;
}

@media only screen
and (max-device-width: 768px) {
    .field_style {
        height: 40px;
        background: #FFF;
        border-radius: 32px!important;
    }

    .field_style div {
        height: 40px;
        border-radius: 32px!important;
    }

    .field_style label {
        top: -6px!important;
        padding-left: 0;
    }
}
