.wrapper {
    background: #122932;
    width: 100%;
    height: calc(100vh - 217px);
    display: flex;
    justify-content: center;
}

.motion_div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    margin-top: 64px;
    background: linear-gradient(221.54deg, #009DE0 0%, #122932 93.29%);
    border-radius: 30px;
    width: 575px;
    height: 578px;
    padding: 60px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    z-index: 999999;
}

.title {
    font-family: 'Kanit', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 76.8px;
    text-transform: uppercase;
    color: #FAFAFA;
    margin-bottom: 48px;
    text-align: center;
}

.input_block {
    margin-bottom: 20px;
    padding: 0 80px;
}

.btn_block {
    width: 180px;
    margin: 30px auto;
}
.link_block {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #B5B5B5;
    margin-bottom: 10px;
    justify-content: center;
}
.error_block{
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: red;
    margin-bottom: 10px;
    justify-content: center;
}
.link {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: #009DE0;
    margin-left: 4px;
}

@media only screen
and (max-device-width: 768px) {
    .content {
        margin-top: 64px;
        background: linear-gradient(221.54deg, #009DE0 0%, #122932 93.29%);
        border-radius: 30px;
        width: 575px;
        height: fit-content;
        padding: 48px 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        z-index: 1;
    }

    .title {
        font-size: 40px;
        margin-bottom: 40px;
        text-align: center;
        line-height: 60px;
    }

    .wrapper {
        height: calc(100vh - 141px);
    }

    .btn_block {
        width: 100%;
        display: flex;
        justify-content: center;
        width: 180px;
    }

}
@media only screen
and (max-device-width: 425px) {
    .content {
        padding: 48px 0px;
    }
    .input_block {
        padding: 0 10px;
    }
}
