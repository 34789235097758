.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #122932;
    width: 100%;
}

.content {
    width: 1440px;
    padding: 0 100px;
    box-sizing: border-box;
    margin: 0 auto;
}

.motion_div {
    width: 100%;
}

.title {
    font-family: 'Kanit', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 96px;
    text-transform: uppercase;
    color: #FAFAFA;
    margin-top: 100px;
    margin-bottom: 60px;
}

.collapse_text {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #122932;
    width: 100%;
}

.panelStyle {
    margin-bottom: 20px;
    background: #FAFAFA;
    border-radius: 24px;
    border: none!important;
    padding: 24px 24px;
    color: #122932;
    font-size: 24px;
    font-weight: 700;
    align-items: center;
    cursor: pointer;
}

.icon_plus {
    transition: .2s all ease;
    border-radius: 50%;
    background: #009DE0;
}

.icon_minus {
    transition: .2s all ease;
    border-radius: 50%;
}

.my_collapse:hover .icon_plus {
    background: #0D4C66;
    transition: .2s all ease;
    border-radius: 50%;
}

.my_collapse:hover .icon_minus {
    background: #0D4C66;
    transition: .2s all ease;
    border-radius: 50%;
}

.panelStyle:hover {
    background: linear-gradient(270deg, #FFFFFF 30.73%, #93D6F2 75.52%, #009DE0 100%);
}

.panelStyle:last-child {
    border-radius: 24px!important;
}

.panelStyle div {
    align-items: center!important;
    padding: 0!important;
}

.panelStyle_hovered {
    align-items: center!important;
    border: none!important;
}

.panelStyle_hovered div {
    align-items: center!important;
}

.collapse_block {
    margin-bottom: 140px;
}

@media only screen
and (max-device-width: 768px) {
    .content {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
        margin: 0 auto;
    }

    .title {
        font-size: 28px;
        line-height: 42px;
        margin-bottom: 32px;
    }

    .panelStyle {
        margin-bottom: 16px;
        font-size: 20px;
    }
}
