.title {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #122932;
    margin-bottom: 40px;
}

.client_details_wrapper {
    background: #FAFAFA;
    border-radius: 30px;
    padding: 32px;
    margin-bottom: 28px;
}

.client_info {
    width: 80%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 52px;
}

.add_info .client_info {
    width: 100%;
    height: fit-content;
    margin-bottom: 20px;
}

.qr_block img {
    width: 50px;
    height: 50px;
}

.block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
}

.client_details_btn_block {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
}

.add_account_button_wrapper {
    width: 210px;
}

.right {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.field_name {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #122932;
    width: 50%;
}

.field_value {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0D4C66;
    width: 65%;
}

.accounts {

}

.accounts_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
}

.accounts_title {
    font-family: 'Georgia', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #131313;
}

.accounts_table {
    width: 100%;
    margin-bottom: 32px;
}

.my_select {
    margin-left: 8px;
    outline: none;
    text-decoration: none;
}

.table_header {
    display: flex;
    justify-content: space-between;
    background: #122932;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    align-items: center;
    color: #FAFAFA;
    height: 76px;
    padding: 8px 32px;
    border-radius: 30px 30px 0 0;
}

.header_item_long {
    width: 23%;
    margin-right: 14px;
}

.header_item_status {
    width: 11%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header_item_short {
    width: 17%;
    text-align: right;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;
}

.header_item_currency {
    width: 90px;
    text-align: right;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;
}

.table_row {
    display: flex;
    justify-content: space-between;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    align-items: center;
    color: #122932;
    height: 48px;
    padding: 0 32px;
    background: #FAFAFA;
}

.table_row:nth-child(odd) {
    background: #D6DADB;
}

.currency {
    margin-bottom: 4px;
    padding-right: 4px;
}

.table_footer {
    height: 76px;
    background: #FAFAFA;
    border-radius: 0 0 30px 30px;
    display: flex;
    align-items: center;
    padding: 0 32px;
}

.accounts_counter {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #122932;
}

@media only screen
and (max-device-width: 768px) {

    .client_details_wrapper {
        background: #FAFAFA;
        border-radius: 30px;
        padding: 28px 16px;
        margin-bottom: 28px;
    }

    .title {
        font-size: 20px;
        margin-bottom: 24px;
    }

    .client_info {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    .block {
        width: 100%;
    }

    .row {
        margin-bottom: 12px;
        align-items: flex-start;
    }

    .field_value {
        word-break: break-all;
        color: #0D4C66;
    }

    .table_header {
        position: absolute;
        display: flex;
        justify-content: initial;
        flex-direction: column;
        background: #122932;
        font-family: 'Mulish', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        align-items: center;
        color: #FAFAFA;
        height: fit-content;
        width: 180px;
        padding: 0 0 0 16px;
        border-radius: 30px 0 0 30px;
    }

    .client_details_btn_block {
        width: 100%;
    }

    .add_account_button_wrapper {
        width: 100%;
    }

    .accounts {
        margin-bottom: 87px;
    }

    .header_item_long {
        width: 100%;
        margin-right: 0;
        padding: 12px 0 12px 8px;
        border-bottom: 1px solid #FAFAFA;
        height: 42px;
        box-sizing: border-box;
    }

    .header_item_short {
        width: 100%;
        text-align: left;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        padding: 12px 0 12px 8px;
        border-bottom: 1px solid #FAFAFA;
        height: 42px;
        box-sizing: border-box;
    }

    .header_item_currency {
        width: 100%;
        text-align: left;
        display: flex;
        align-items: center;
        flex-direction: initial;
        justify-content: flex-start;
        padding: 5px 0 5px 8px;
        border-bottom: 1px solid #FAFAFA;
        height: 42px;
        box-sizing: border-box;
    }

    .currency {
        margin-bottom: 0;
        padding-right: 4px;
    }

    .header_item_status {
        width: 100%;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 12px 0 12px 8px;
        height: 42px;
        box-sizing: border-box;
    }

    .table_header:last-child {
        border-bottom: none;
    }

    .table_footer {
        display: none;
    }

    .row_wrapper {
        margin-left: 180px;
        display: flex;
        overflow: scroll;
    }

    .accounts_table {
        position: relative;
        display: flex;
        height: fit-content;
    }

    .table_row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
        line-height: 130%;
        height: fit-content;
        padding: 0;
        background: #FAFAFA;
        min-width: fit-content;
    }

    .field_name {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
    }

    .field_value {
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
    }
}
