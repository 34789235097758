.flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}

.short_input {
    width: 295px;
}

.textarea {
    margin-top: 20px;
    margin-bottom: 32px;
    width: 100%;
    background: #FAFAFA;
    border-radius: 32px;
    outline: none;
    border: none;
    height: 160px;
    resize: none;
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    padding: 17px 30px;
    box-sizing: border-box;
}

.btn_block {
    display: flex;
    justify-content: flex-end;
}

.btn_wrapper {
    width: fit-content;
}

.input_error {
    outline: 1px solid #FF3124;
}

@media only screen
and (max-device-width: 768px) {
    .flex {
        flex-direction: column;
    }

    .short_input {
        width: 100%;
    }

    .short_input:first-child {
        margin-bottom: 20px;
    }

    .btn_wrapper {
        width: 100%;
    }
}
