.container {
    width: 60%;
}

.title {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #122932;
    margin-bottom: 30px;
}

.text {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #122932;
}
.inputContainer {
    width: 60%;
    margin-top: 30px;
}
.inputWrap {
    margin-bottom: 30px;
}
.btnWrap {
    width: 140px;
    height: 60px;
}

@media only screen
and (max-device-width: 820px) {
    .container {
        width: 100%;
        padding: 30px;
    }
    .inputContainer {
        width: 100%;
    }
    .btnWrap {
        width: 100%;
    }
}
