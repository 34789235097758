.wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 594px;
    background: #FAFAFA;
    border-radius: 30px;
    padding: 90px 16px;
    box-sizing: border-box;
}

.title {
    font-family: 'Kanit', serif;
    font-style: italic;
    font-weight: 275;
    font-size: 60px;
    line-height: 130%;
    color: #122932;
    margin-bottom: 24px;
}

.subtitle {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #122932;
    margin-bottom: 40px;
}

.btns_block {
    display: flex;
    justify-content: space-between;
    width: 454px;
    height: 58px;
}

.my_btn_yes:hover {
    background: #D6AD72;
    transition: .5s all ease;
}

.btn_wrapper {
    width: 217px;
}

@media only screen
and (max-device-width: 768px) {

    .wrapper {
        position: fixed;
    }

    .content {
        width: 375px;
        height: 372px;
        padding: 40px 16px;
    }

    .content img {
        width: 60px;
        height: 60px;
    }

    .btns_block {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        height: 100px;
    }

    .btn_wrapper {
        width: 100%;
    }

    .title {
        margin-top: 20px;
        font-size: 28px;
        line-height: 130%;
        margin-bottom: 20px;
    }

    .subtitle {
        font-family: 'Mulish', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        color: #122932;
        margin-bottom: 30px;
    }
}
